<script setup lang="ts">
</script>

<template>
  <page-container>
    <h1>设备台账</h1>
  </page-container>
</template>

<style lang="scss" scoped>
</style>
